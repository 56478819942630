import React, { ReactNode } from 'react';
import Heading from '../components/core/typography/Heading';
import { Container } from '../components/core/layout/Layout';
import { OurServicesImagesQuery } from '../../generated/graphql-types';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { howItWorks } from '../page-data/il-servizio';
import SEO from '../components/core/meta/SEO';
import {
  getAppRedirectUri,
  HOSTNAME,
} from '../components/utils/getAppRedirectUri';
import CollectEmail from '../components/partials/CollectEmail/CollectEmail';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { renderOptions } from '../contentful/render-options';
import FaqAccordion from '../components/views/FaqAccordion/FaqAccordion';
import Button from '../components/core/buttons/Button';
import tw from 'twin.macro';
import VideoContainer from '../components/core/misc/VideoContainer';
import useDatalayer from '../../hooks/use-datalayer';
import { useAnalytics } from '../analytics/AnalyticsProvider';

const OurServices = () => {
  const [appLocation, setAppLocation] = React.useState<string>(
    '//app.' + HOSTNAME
  );

  React.useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  const datalayer = useDatalayer();
  const analytics = useAnalytics();

  const ourServicesImages = useStaticQuery<OurServicesImagesQuery>(graphql`
    query OurServicesImages {
      services1: file(
        relativePath: { eq: "services/1. Chiedi un preventivo.png" }
      ) {
        childImageSharp {
          fixed(height: 240) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      services2: file(
        relativePath: {
          eq: "services/2. Confermi e organizziamo il servizio.png"
        }
      ) {
        childImageSharp {
          fixed(height: 240) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      services3: file(
        relativePath: { eq: "services/3. Sei sicuro e protetto.png" }
      ) {
        childImageSharp {
          fixed(height: 240) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      services4: file(
        relativePath: { eq: "services/4. Paghi e lasci una recensione.png" }
      ) {
        childImageSharp {
          fixed(height: 240) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      ilS1: file(relativePath: { eq: "il_s1.png" }) {
        childImageSharp {
          fixed(height: 240) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      ilS2: file(relativePath: { eq: "il_s2.png" }) {
        childImageSharp {
          fixed(height: 240) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      last: file(relativePath: { eq: "IscrivitiNewsletter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      comeFaq: allContentfulFaq(
        filter: { category: { eq: "Come Funziona Page" } }
      ) {
        edges {
          node {
            question {
              question
            }
            answer {
              raw
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Come funziona" url="/il-servizio" />
      <section>
        <Container tw="py-16">
          <Heading variant="h1">Come funziona il servizio di UGO</Heading>
          <p tw="text-paragraph mb-4">
            Per noi di UGO è importante <b>innovare i servizi alla persona</b>.
            Abbiamo studiato e ascoltato tanto i bisogni delle persone. Abbiamo
            capito che i servizi presenti sul mercato hanno due grandi problemi:
            sono frammentati in una moltitudine di piccole realtà in cui nessuno
            sa a chi chidere, di chi fidarsi; non riescono a garantire il
            servzio in tempi rapidi.
          </p>

          <p tw="text-paragraph mb-4">
            Per creare una <b>soluzione a portata di mano 7 giorni su 7</b>, in
            ogni parte di Italia, dobbiamo sfruttare la tecnologia. Per questo
            motivo abbiamo costruito una{' '}
            <b>piattaforma online agile, semplice, sicura</b>. La piattaforma è
            la trasposizione del nostro lavoro ‘artigianale’ che reso automatico
            può rispondere ai bisogni di migliaia di utenti, in modo
            impeccabile.
          </p>

          <p tw="text-paragraph mb-4">
            La <b>prenotazione automatica è semplicissima</b> e adatta a chi non
            ha familiarità col digitale. Fatta una volta, non avrai più timori!
          </p>

          <p tw="text-paragraph">
            Sappiamo che tante persone non si fidano del{' '}
            <b>
              pagamento online ma vi garantiamo di aver scelto il sistema più
              sicuro, leader di mercato, usato dai migliori servizi online. Si
              chiama Stripe ed è certificato:
            </b>{' '}
            i vostri dati sono al sicuro e per ogni problema il nostro servizio
            clienti è pronto a intervenire. Il metodo online è l’unica garanzia
            di pagamenti <b>tracciati e tariffe trasparenti. Significa</b>{' '}
            correttezza, un valore in cui crediamo molto e che vogliamo
            promuovere.
          </p>

          <div tw="pt-16 pb-10">
            {howItWorks.map((h, i) => (
              <div
                key={h.title + i}
                tw="flex flex-wrap items-center rounded-card shadow-card px-10 mb-6 py-5"
              >
                <div tw="w-full lg:w-3/5">
                  <Heading variant="h4" tw="mt-0">
                    {h.title}
                  </Heading>
                  <div tw="text-paragraph">{h.description}</div>
                </div>
                <div tw="w-full lg:w-2/5 flex justify-center md:justify-end">
                  <Image
                    alt={h.title}
                    fixed={ourServicesImages[h.image].childImageSharp.fixed}
                  />
                </div>
              </div>
            ))}
          </div>
          <div tw="flex justify-center">
            <Link to="/#hero">
              <Button
                css={[tw`mr-4`]}
                variant="filled"
                color="primary"
                onClick={() => {
                  analytics.submitEvent<'r-intent-p'>('r-intent-p', {
                    location: 'm-qf-il_servizio_button',
                  });
                  if (typeof window !== 'undefined') {
                    datalayer.v3.triggerFunnelStarted(window.location.pathname);
                  }
                }}
              >
                Calcola preventivo
              </Button>
            </Link>
          </div>
        </Container>
      </section>
      <section tw="bg-primary-300 py-16">
        <Container>
          <div tw="flex flex-wrap -mx-4 md:-mx-10">
            <div tw="w-full md:w-1/2 px-4 md:px-10 mb-6 md:mb-0">
              <div tw="rounded-card p-8 shadow-card bg-white">
                <div tw="flex justify-center mb-4">
                  <Image fixed={ourServicesImages.ilS1.childImageSharp.fixed} />
                </div>
                <Heading variant="h5" tw="text-center">
                  Operatori qualificati
                </Heading>
                <p tw="text-paragraph">
                  I Caregiver UGO sono <b>selezionati e formati</b>. Sono
                  persone che conoscono i problemi legati alle prime fragilità,
                  svolgono con attenzione il lavoro, parlano perfettamente
                  l’italiano.{' '}
                  <b>Supportano con empatia e discrezione la persona:</b>
                  il loro scopo non è accudire come una badante, ma instaurare
                  un rapporto equilibrato, di rispetto, dignità.
                </p>
              </div>
            </div>
            <div tw="w-full md:w-1/2 px-4 md:px-10">
              <div tw="rounded-card p-8 shadow-card bg-white">
                <div tw="flex justify-center mb-4">
                  <Image fixed={ourServicesImages.ilS2.childImageSharp.fixed} />
                </div>
                <Heading variant="h5" tw="text-center">
                  Servizio recensito
                </Heading>
                <p tw="text-paragraph">
                  In linea con le esigenze di oggi, UGO è flessibile e calibrato
                  sulle tue esigenze.{' '}
                  <b>Dopo ogni servizio i nostri utenti inviano il feedback:</b>{' '}
                  in questo modo abbiamo costantemente visibilità sull’operato
                  dei nostri accompagnatori e possiamo intervenire per
                  correggere eventuali problemi.
                  <b>Il livello di soddisfazione medio degli utenti è 4.8/5.</b>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section tw="pt-16">
        <Container>
          <VideoContainer tw="mb-10">
            <iframe
              src="https://www.youtube.com/embed/1bWQ32JGr-g"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoContainer>
          <div tw="flex justify-center">
            <Link to="/#hero">
              <Button variant="filled" color="primary" tw="px-20!">
                Prenota ora
              </Button>
            </Link>
          </div>
        </Container>
      </section>
      <section tw="pt-16">
        <Container>
          <Heading variant="h2">Domande frequenti</Heading>
          <FaqAccordion
            faqItems={ourServicesImages.comeFaq.edges.map(({ node }) => ({
              answer: renderRichText(
                // @ts-ignore
                node.answer,
                renderOptions
              ) as ReactNode,
              question: node.question.question,
            }))}
          />
        </Container>
      </section>
      <section tw="py-16">
        <Container>
          <div tw="flex flex-wrap -mx-4 items-center">
            <div tw="w-full lg:w-2/5 p-4">
              <Heading variant="h4" tw="text-center">
                Entra nella Community UGO!
              </Heading>
              <p tw="text-center text-paragraph mb-5">
                Resta aggiornato sui temi legati alla terza età, alla gestione
                della salute, all’equilibrio in famiglia. Approfondimenti,
                momenti di confronto e consigli pratici per affrontare al meglio
                e col sorriso ogni fase della vita.
              </p>
              <div>
                <CollectEmail />
              </div>
            </div>
            <div tw="w-full lg:w-3/5 p-10">
              <Image
                alt="laptop"
                tw="rounded-lg"
                fluid={ourServicesImages.last.childImageSharp.fluid}
              />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default OurServices;
